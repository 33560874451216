import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { forwardRef, useState } from 'react';
import { SUBSCRIBE_RESOURCE, UNSUBSCRIBE_RESOURCE } from '../../../constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { StyledLink } from "../random";
import TripImageCarousel from "../carousel/TripImageCarousel";
import { apiHelper } from '../../../utils/apiHelper';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { formatINR } from "../../../utils/prices";
import styled from 'styled-components';
import { useUserData } from "../../../hooks/useUserData";

const Card = styled.div`
  width: 400px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  min-height: 35rem;
  margin-bottom: 2rem;
  background: white;

  @media(max-width: 450px){
    width: 350px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Location = styled.div`
  color: #888;
  font-size: 12px;
`;


const Content = styled.div`
  padding: 16px;
`;

const Title = styled.h2`
  font-size: 18px;
  margin: 0 0 10px 0;
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  color: #555;

  &::before {
    content: '📅';
    margin-right: 8px;
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px 0;
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #5753c9;
  }
`;


const TripCard = forwardRef(({ tripDetails, is_wishlisted }, ref) => {
  const pageUrl = "/trip/" + tripDetails.id;
  const [isWishlisted, setIsWishlisted] = useState(is_wishlisted);
  // setIsWishlisted(is_wishlisted)

  const userData = useUserData()

  const wishlistHandler = (tripId, is_wishlisted) => {
    apiHelper({
      url: !is_wishlisted ? SUBSCRIBE_RESOURCE : UNSUBSCRIBE_RESOURCE, method: "POST", data: {
        "resource_type": "trip",
        "resource_id": tripId
      }
    }).then((response) => {
      console.log(response.data)
      setIsWishlisted(!is_wishlisted)

    })
      .catch((error) => {
        // TODO: do a proper error handling error displaying to user
        // alert("something went wrong, we are looking into it");
      })
  }



  return (
    <Card ref={ref}>
      <Header>
        <StyledLink to={`/user/${tripDetails.host_username}`}>
        <Avatar src={tripDetails.host_image} alt="profile image" />
        </StyledLink>
        <StyledLink to={`/user/${tripDetails.host_username}`}>
        <Info>
          <div>by {tripDetails.host_username}</div>
          <Location>{tripDetails.city}, {tripDetails.state}</Location>
        </Info>
        </StyledLink>
        {userData?.isLoggedIn ? (<FontAwesomeIcon
          onClick={() => wishlistHandler(tripDetails.id, isWishlisted)}
          icon={faHeart}
          style={{
            height: "24px",
            marginLeft: "auto",
            cursor: "pointer",
            color: isWishlisted ? "red" : "lightgrey"
          }}
        />) : (<></>)}      </Header>
      <TripImageCarousel images={tripDetails.photos_small || []} />
      <Content>
        <Title>{tripDetails.title}</Title>
        <Date>{tripDetails.date[0]}</Date>
        <Price>{formatINR(tripDetails.price)}</Price>
        <Link to={pageUrl}><Button>View Details</Button></Link>
      </Content>
    </Card>
  );
});

export default TripCard;
