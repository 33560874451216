import {
  Container,
  LeftSidebar,
  MainContent,
  RightSidebar,
  SideBarTopPostContainer,
} from "../../containers/ThreeColumnContainer";
import { FETCH_EVENT_BY_LOCATION_SEARCH, FETCH_HOME_FEED_TRIPS, FETCH_USER_WISHLISTED_TRIPS } from "../../../constants";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import BottomBar from "../../navigation/BottomBar";
import Navbar from "../../navigation/NavBar";
import SearchBar from "../community/SearchBar";
import TopPosts from "../../HomePage/TopPosts";
import TripCard from "../../ui/TripCard/TripCard";
import { apiHelper } from "../../../utils/apiHelper";
import { useNavigate } from 'react-router-dom';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cleanup the timeout if value or delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

function HomePage() {
  const [tripsList, setTripsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [wishlistedTripIds, setWishlistedTripIds] = useState([]);
  const observer = useRef();
  const navigate = useNavigate();

  const itemsPerPage = 2; // Number of items per page

  // Construct the URL dynamically for either search or home feed
  const requestUrl = useMemo(() => {
    
      return `${FETCH_HOME_FEED_TRIPS}?page=${currentPage}&limit=${itemsPerPage}`;
    
  }, [currentPage]);

  // Fetch trips from the API
  useEffect(() => {
    apiHelper({ url: requestUrl })
      .then((response) => {
        const newTrips = response.data;

        // Append trips for homepage feed
        setTripsList((prevTrips) => [...prevTrips, ...newTrips]);

        // Check if more trips are available for pagination
        if (newTrips.length < itemsPerPage) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      })
      .catch((error) => {
        // Handle error (if any)
      });
  }, [requestUrl]);

  
  // Fetch wishlisted trips
  useEffect(() => {
    apiHelper({ url: FETCH_USER_WISHLISTED_TRIPS })
      .then((response) => {
        const wishlisted_trip_ids = response.data.map(trip => trip.id);
        setWishlistedTripIds(wishlisted_trip_ids);
      })
      .catch((error) => {
        // Handle error (if any)
      });
  }, []);

  // Infinite scrolling logic using IntersectionObserver
  const lastTripElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setCurrentPage((prevPage) => prevPage + 1); // Load next page
      }
    });

    if (node) observer.current.observe(node);
  }, [hasMore]);

  const handleSearchValueChange = (value) => {
    console.log("search value getting changed");
    setSearchQuery(value);
  }

  const handleSearchSubmit = () => {
    if (searchQuery.trim()) {
      navigate(`/search?q=${searchQuery}`);
    }
  };

  return (
    <>
      <Navbar />
      <SearchBar
        value={searchQuery}
        placeholder={"Search for your favorite locations"}
        onChange={setSearchQuery}
        onSubmit={handleSearchSubmit}
      />

      <Container>
        <LeftSidebar></LeftSidebar>
        <MainContent>
          {tripsList.map((trip, index) => {
            if (tripsList.length === index + 1) {
              return <TripCard ref={lastTripElementRef} key={trip.id} tripDetails={trip} is_wishlisted={wishlistedTripIds.includes(trip.id)} />;
            } else {
              return <TripCard key={trip.id} tripDetails={trip} is_wishlisted={wishlistedTripIds.includes(trip.id)} />;
            }
          })}
        </MainContent>
        <RightSidebar>
          <SideBarTopPostContainer>
            <TopPosts />
          </SideBarTopPostContainer>
        </RightSidebar>
      </Container>
      <BottomBar />
    </>
  );
}

export default HomePage;
