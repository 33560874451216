import React, { useEffect, useState } from 'react';

import BottomBar from '../../navigation/BottomBar';
import { FETCH_INTERESTED_USERS } from '../../../constants';
import { FETCH_TRIP_BY_ID } from '../../../constants';
import Itinerary from "../../ui/iternary/Iternary";
import Navbar from '../../navigation/NavBar';
import { StyledLink } from "../../ui/random";
import TripImageCarousel from "../../ui/carousel/TripImageCarousel";
import { UPDATE_TRIP_INTEREST } from '../../../constants';
import { apiHelper } from "../../../utils/apiHelper";
import { formatINR } from '../../../utils/prices';
import { styled } from 'styled-components';
import { useParams } from "react-router-dom";

// Styled components
const ImageCarouselContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  @media (max-width: 906px) {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 80%;
  display: flex;
  column-gap: 2rem;
  padding: 0 1rem;
  margin: 0 auto;
  @media (max-width: 906px) {
    width: 95%;
  }
  @media (max-width: 790px) {
    flex-direction: column;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
  margin-bottom: 7vh;
`;

const LefContainer = styled.div`
  padding-top: 3rem;
  width: 60%;
  @media (max-width: 906px) {
    width: 100%;
  }

  @media (max-width: 790px) {
    padding-top : 0rem;
  }
`;

const RightContainer = styled.div`
  padding-top: 3rem;
  width: 30%;
  
  @media (max-width: 906px) {
    width: 100%;
  }
  
  @media (max-width: 790px) {
    display: none;
  }

`;

const RightContainerV2 = styled.div`
  padding-top: 3rem;
  width: 30%;
  
  @media (max-width: 790px) {
    padding-top : 1rem;
  }
  @media (max-width: 906px) {
    width: 100%;
  }
  @media (min-width: 790px) {
    display: none;
  }
  

`;

const TextContent = styled.div`
@media (max-width: 790px) {
    padding-top : 1rem;
  }

`;


const Description = styled.p`
  word-spacing: 1.5px;
  font-size: 0.9rem;
  color: #646363;
  padding: 1rem;
  background-color: white;
  margin-top: 1rem;
  @media (max-width: 906px) {
    font-size: 1.1rem;
  }
  @media (max-width: 790px) {
    margin-top : 0rem;
  }
`;

const HostedBy = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 0;
`;

// Price card styling
const PriceCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.8rem;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  @media (max-width: 790px) {
    margin-bottom: 1rem;

  }
`;

const StartingPrice = styled.p`
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
`;

const Price = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
  margin-top: 0;
`;

const PricePerAdult = styled.span`
  font-size: 12px;
  color: #888;
`;

const DiscountBadge = styled.div`
  background-color: #ff9900;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
`;

// Header styling
const HeaderContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  border-bottom: 1px solid #ccc;
  div{
    display: flex;
    align-items: center;
  }
`;

const VerticalLine = styled.div`
  width: 5px;
  height: 2rem;
  background-color: #ff9900;
  margin-right: 10px;
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
`;

const ReadMoreButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
    &:hover {
    text-decoration: underline;
  }
  @media (max-width: 906px) {
    font-size: 1.1rem;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px 0;
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #5753c9;
  }
`;

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '600px',
  },
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111111;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const FormField = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const FormButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;


// Form Component
const InterestForm = ({ onSubmit, closeModal, bioPitch, instaHandle, setBioPitch, setInstaHandle }) => {
  return (
    <div>
      <h3>Show Interest in the Trip</h3>
      <form onSubmit={onSubmit}>
        <label>
          Instagram Handle:
          <input type="text" value={instaHandle} onChange={(e) => setInstaHandle(e.target.value)} required />
        </label>
        <label>
          Bio Pitch:
          <textarea value={bioPitch} onChange={(e) => setBioPitch(e.target.value)} required />
        </label>
        <button type="submit">Submit Interest</button>
        <button type="button" onClick={closeModal}>Cancel</button>
      </form>
    </div>
  );
};

// Interested Users List Component
const InterestedUsersList = ({ users }) => {
  return (
    <div>
      <h3>Interested Users</h3>
      <ul>
        {users.map(user => (
          <li key={user.id}>
            {user.name} - {user.insta_handle}
          </li>
        ))}
      </ul>
    </div>
  );
};

// Component implementations
const PriceCard = ({ strikeOffPrice, currentPrice }) => {
  return (
    <PriceCardContainer>
      <StartingPrice>Starting from INR {strikeOffPrice}</StartingPrice>
      <Price>{currentPrice}</Price>
      <PricePerAdult>per Adult</PricePerAdult>
    </PriceCardContainer>
  );
};

const Header = ({ title, hostedBy, onInterestClick }) => {

  return (
    <HeaderContainer>
      <div>
        <VerticalLine />
        <div>
          <Title>{title}</Title>
        </div>
      </div>
      <HostedBy>
        Hosted by - <StyledLink to={`/user/${hostedBy}`}><span>{hostedBy}</span></StyledLink>
      </HostedBy>
      <Button onClick={() => onInterestClick()}>Show Interest</Button>
    </HeaderContainer>
  );
};

const CollapsibleDescription = ({ text, limit = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedText = text.length > limit ? text.substring(0, limit) + '...' : text;

  return (
    <>
      <Description>
        {isExpanded ? text : truncatedText}
        {text.length > limit && (
          <ReadMoreButton onClick={toggleDescription}>
            {isExpanded ? "Read Less" : "Read More"}
          </ReadMoreButton>
        )}
      </Description>

    </>
  );
};

const InterestModal = ({ isOpen, onClose, tripId, onSubmit }) => {
  const [instaHandle, setInstaHandle] = useState('');
  const [bioPitch, setBioPitch] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(tripId, instaHandle, bioPitch);
    setInstaHandle('');  // Reset form after submission
    setBioPitch('');
    onClose();  // Close the modal
  };

  if (!isOpen) return null;

  return (
    // Close modal if clicked on overlay (but not on the content itself)
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}> {/* Prevent closing when clicking inside */}
        <CloseButton onClick={onClose}>×</CloseButton>
        <form onSubmit={handleSubmit}>
          <FormField>
            <Label>Instagram Handle</Label>
            <Input
              type="text"
              value={instaHandle}
              onChange={(e) => setInstaHandle(e.target.value)}
              placeholder="Enter your Instagram handle"
              required
            />
          </FormField>
          <FormField>
            <Label>Bio Pitch</Label>
            <Input
              type="text"
              value={bioPitch}
              onChange={(e) => setBioPitch(e.target.value)}
              placeholder="Tell why you're interested"
              required
            />
          </FormField>
          <Button type="submit">Submit Interest</Button>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

function TripDetails() {
  const params = useParams();
  const [tripDetail, setTripDetail] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [interestedUsers, setInterestedUsers] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 549);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const imageList = isMobile ? tripDetail.photos_small : tripDetail.photos;

  useEffect(() => {
    apiHelper({
      url: `${FETCH_TRIP_BY_ID}/${params.tripId}`,
    })
      .then((response) => {
        setTripDetail(response.data);
      })
      .catch((error) => {
        // Error handling placeholder
      });
  }, [params.tripId]);

  const handleInterestClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsFormSubmitted(false);
  };

  const handleFormSubmit = (tripId, instaHandle, bioPitch) => {
    const data = {
      trip_id: tripId,
      bio_pitch: bioPitch,
      contact_insta_handle: instaHandle,
    };

    apiHelper({
      url: UPDATE_TRIP_INTEREST,
      method: 'POST',
      data: data,
    })
      .then((response) => {
        console.log('Interest submitted successfully');
        fetchInterestedUsers();  // Fetch interested users after successful form submission
        setIsFormSubmitted(true);
      })
      .catch((error) => {
        console.log('Error while submitting interest');
      });
  };

  const fetchInterestedUsers = () => {
    apiHelper({
      url: `${FETCH_INTERESTED_USERS}/${params.tripId}`,
    })
      .then((response) => {
        setInterestedUsers(response.data);
      })
      .catch((error) => {
        console.log('Error fetching interested users');
      });
  };

  return (
    <>
      <Navbar />
      <ImageCarouselContainer>
        <TripImageCarousel source={"PDP"} images={imageList || []} />
      </ImageCarouselContainer>
      <Container>
        <LefContainer>
          <TextContent>
            <Header title={tripDetail.title} hostedBy={tripDetail.host_username} onInterestClick={() => handleInterestClick(tripDetail.id)} />
          </TextContent>
          <RightContainerV2>
            <PriceCard strikeOffPrice={formatINR(tripDetail.price)} currentPrice={formatINR(tripDetail.price)} />
          </RightContainerV2>
          <CollapsibleDescription text={tripDetail.description || ""} limit={150} />
          <Itinerary itinerary={tripDetail.itenary} />
        </LefContainer>
        <RightContainer>
          <PriceCard strikeOffPrice={formatINR(tripDetail.price)} currentPrice={formatINR(tripDetail.price)} />
        </RightContainer>
      </Container>
      <BottomBar />
      <InterestModal
        isOpen={isModalOpen}
        onClose={closeModal}
        tripId={tripDetail.id}
        onSubmit={handleFormSubmit}
      />

      {/* Display interested users after form submission */}
      {isFormSubmitted && (
        <div>
          <h3>Users Interested in this Trip</h3>
          <ul>
            {interestedUsers.map((user) => (
              <li key={user.id}>{user.contact_insta_handle} - {user.bio_pitch}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default TripDetails;
