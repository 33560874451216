

export const APP_TITLE = 'Development Environment'
export const EVENT_CATEGORIES_URL = 'https://test.soccal.in/api/web/v1/eventCategories'
export const SAVE_USER_INTERESTS = 'https://test.soccal.in/api/web/v1/travel/interest'
export const SAVE_USER_EXISTING_PLANS = 'https://test.soccal.in/api/web/v1/travelplans/add'
export const GOOGLE_LOGIN_API = 'https://test.soccal.in/api/web/v1/auth/google/validate'
export const FETCH_HOME_FEED_TRIPS = 'https://test.soccal.in/api/v2/feed'
export const FETCH_USER_WISHLISTED_TRIPS = 'https://test.soccal.in/api/v2/user/wishlist'

export const FETCH_TRIP_BY_ID = 'https://test.soccal.in/api/v2/trips'
export const FETCH_PUBLIC_USER_PROFILE = 'https://test.soccal.in/api/v2/user/'
export const FETCH_TRIPS_BY_PUBLIC_PROFILES = 'https://test.soccal.in/api/v2/user/%s/trips?pastTrips=%s'
export const FETCH_PRIVATE_USER_PROFILE = 'https://test.soccal.in/api/v2/user/basic-details'
export const FETCH_ALL_COMMUNITIES = 'https://test.soccal.in/api/v2/communities/'
export const FOLLOW_COMMUNITY = 'https://test.soccal.in/api/v2/subscribe'
export const LEAVE_COMMUNITY = 'https://test.soccal.in/api/v2/unsubscribe'
export const FETCH_FOLLOWED_COMMUNITIES = 'https://test.soccal.in/api/v2/communities/followed'
export const FETCH_EVENT_BY_LOCATION_SEARCH = "https://test.soccal.in/api/v2/search"
export const FETCH_COMMUNITY_TRIPS = "https://test.soccal.in/api/v2/communities/"
export const FETCH_COMMUNITY_META_DATA = "https://test.soccal.in/api/v2/communities/"

export const SUBSCRIBE_RESOURCE = 'https://test.soccal.in/api/v2/subscribe'
export const UNSUBSCRIBE_RESOURCE = 'https://test.soccal.in/api/v2/unsubscribe'

export const FETCH_USER_TRIP_INTEREST = 'https://test.soccal.in/api/v2/trip-interest'
export const UPDATE_TRIP_INTEREST = 'https://test.soccal.in/api/v2/trip-interest'
export const FETCH_TRIP_INTEREST_BY_TRIP_ID = 'https://test.soccal.in/api/v2/trip-interest/'
export const FETCH_INTERESTED_USERS = 'https://test.soccal.in/api/v2/trip-interest/'


// adding color constants hex codes here for our colors
export const PRIMARY_DARK = "#b280c9";
export const PRIMARY = "#cb9ee1";
export const PRIMARY_LIGHT = "#dab8e8";
